/* App.css */

/* 공통 스타일 */
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  background-color: #f9fafb;
  color: #333;
  overflow-x: hidden;
}

.App {
  text-align: center;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* 헤더 스타일 */
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  background-color: #1f2937;
  color: white;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.header .menu-button {
  position: absolute;
  left: 20px;
  font-size: 26px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.header .menu-button:hover {
  transform: rotate(90deg);
}

.header .page-title {
  font-size: 22px;
  margin: 0;
  font-weight: 500;
}

/* 사이드 메뉴 스타일 */
.side-menu {
  position: fixed;
  top: 64px;
  left: 0;
  width: 220px;
  height: calc(100% - 64px);
  background-color: #111827;
  color: white;
  z-index: 1500;
  display: none;
  transition: transform 0.3s ease;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.side-menu.open {
  display: block;
}

.side-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding-bottom: 20px;
}

.side-menu ul li a {
  text-decoration: none;
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 5px;
  display: block;
  transition: background-color 0.3s ease;
}

.side-menu ul li a:hover {
  background-color: #2563eb;
}

/* 사이드 메뉴 카테고리 제목 스타일 */
.menu-category {
  margin-top: 20px;
  padding: 10px 20px;
  color: #9ca3af;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-bottom: 1px solid #374151;
}

/* 본문 스타일 */
main {
  margin: 80px auto 0 auto;
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 80px);
  max-width: 1200px;
}

/* 카드형 레이아웃 스타일 */
.content-box {
  background-color: white;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.05);
  max-width: 800px;
  width: 100%;
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
}

/* 설명 텍스트 스타일 */
.description {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 16px;
  color: #555;
  line-height: 1.6;
  max-width: 600px;
  text-align: left;
}

/* 입력 그룹 스타일 */
.input-group {
  width: 100%;
  max-width: 500px;
  margin-bottom: 20px;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.input-group input,
.input-group select,
.input-group textarea {
  width: 100%;
  padding: 15px;
  font-size: 16px;
  box-sizing: border-box;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.input-group input::placeholder,
.input-group textarea::placeholder {
  color: #888;
}

.input-group input:focus,
.input-group select:focus,
.input-group textarea:focus {
  border-color: #2563eb;
  box-shadow: 0 0 5px rgba(37, 99, 235, 0.5);
  outline: none;
}

/* 버튼 스타일 */
button {
  padding: 12px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #2563eb;
  color: white;
  border: none;
  border-radius: 8px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin: 5px;
}

button:hover {
  background-color: #1d4ed8;
  transform: translateY(-2px);
}

.reset-button {
  background-color: #6b7280;
}

.reset-button:hover {
  background-color: #4b5563;
}

/* 반응형 설정 */
@media (max-width: 768px) {

  /* 햄버거 버튼은 예외 처리 */
  .header .menu-button {
    width: auto; /* 햄버거 버튼은 width: 100%를 적용하지 않음 */
    margin-right: auto; /* 오른쪽 마진 자동 */
    margin-left: 0; /* 왼쪽 마진 없애기 */
  }

  .header {
    justify-content: center; /* 페이지 타이틀 중앙 정렬 */
  }

  .lotto-number {
    width: 40px;
    height: 40px;
    font-size: 20px;
    line-height: 40px;
  }

  .menu-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 15px;
  }

  .menu-content {
    padding: 20px 15px;
  }

  .menu-content h2 {
    font-size: 1.2rem;
  }

  .menu-content .menu-icon {
    font-size: 2rem;
  }

  .side-menu {
    width: 180px;
  }

  .header .page-title {
    font-size: 20px;
  }

  .content-box {
    padding: 20px;
    max-width: 90%;
  }

  input, select, textarea, button {
    width: 100%;
    margin-right: 0;
  }

  .input-group {
    max-width: 100%;
  }

  .gpa-calculator .course-input {
    flex-direction: row; /* 수평으로 배치 */
    flex-wrap: nowrap; /* 한 줄로 유지 */
    justify-content: space-between;
  }

  .gpa-calculator .input-group {
    width: 70%; /* 입력창이 더 큰 비율을 차지하게 */
  }

  .gpa-calculator .remove-button {
    width: 25%; /* 삭제 버튼 크기를 조정하여 한 줄에 맞춤 */
  }

}

/* 셀렉트 박스 스타일 */
select {
  padding: 12px;
  margin-bottom: 15px;
  font-size: 16px;
  box-sizing: border-box;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

select:focus {
  border-color: #2563eb;
  box-shadow: 0 0 5px rgba(37, 99, 235, 0.5);
  outline: none;
}

/* 추가 스타일링 */

/* 입력 섹션 */
.input-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.input-section input,
.input-section select {
  margin: 10px 0;
  padding: 10px;
  font-size: 16px;
  width: 100%;
  max-width: 300px;
}

/* 답변 섹션 버튼 스타일 */
.answer-section button {
  padding: 10px 20px;
  margin: 10px;
  font-size: 16px;
  background-color: #2563eb;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.answer-section button:hover {
  background-color: #1d4ed8;
}

/* 점수 섹션 스타일 */
.score-section {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-top: 20px;
}

/* 스크롤바 스타일 */
.side-menu::-webkit-scrollbar {
  width: 8px;
}

.side-menu::-webkit-scrollbar-track {
  background: #1f2937;
}

.side-menu::-webkit-scrollbar-thumb {
  background-color: #374151;
  border-radius: 4px;
}

.side-menu::-webkit-scrollbar-thumb:hover {
  background-color: #4b5563;
}

/* 에러 메시지 스타일 */
.error-message {
  color: red;
  margin-top: 10px;
}

/* 만나이 계산기 스타일 */
.content-box.age-calculator {
  text-align: center;
}

/* BMI 계산기 스타일 */
.content-box.bmi-calculator {
  text-align: center;
}

/* 날짜 카운터 스타일 */
.content-box.date-counter {
  text-align: center;
}

/* 단위 변환기 스타일 */
.content-box.unit-converter {
  text-align: center;
}

.unit-converter .unit-selectors {
  display: flex;
  align-items: center;
  justify-content: center;
}

.unit-converter .unit-selectors select {
  flex: 1;
  max-width: 150px;
  margin: 0 5px;
}

.unit-converter .arrow {
  font-size: 24px;
  margin: 0 10px;
}

/* 로또 번호 추천 스타일 */
.lotto-numbers {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.lotto-number {
  display: inline-block;
  margin: 0 5px;
  background-color: #2563eb;
  color: white;
  font-size: 24px;
  font-weight: bold;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  text-align: center;
}

/* 대출 계산기 스타일 */
.content-box.loan-calculator {
  text-align: center;
}

.loan-calculator .loan-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loan-calculator .input-group {
  margin-bottom: 20px;
  width: 100%;
  max-width: 400px;
}

/* 기타 계산기 스타일 */
.content-box.compound-interest-calculator,
.content-box.retirement-calculator,
.content-box.tax-calculator,
.content-box.discount-calculator,
.content-box.salary-calculator,
.content-box.mortgage-calculator,
.content-box.stock-return-calculator,
.content-box.body-fat-calculator {
  text-align: center;
}

.compound-interest-calculator .input-group,
.retirement-calculator .input-group,
.tax-calculator .input-group,
.discount-calculator .input-group,
.salary-calculator .input-group,
.mortgage-calculator .input-group,
.stock-return-calculator .input-group,
.body-fat-calculator .input-group {
  margin-bottom: 20px;
  width: 100%;
  max-width: 400px;
}

.compound-interest-calculator .input-group label,
.retirement-calculator .input-group label,
.tax-calculator .input-group label,
.discount-calculator .input-group label,
.salary-calculator .input-group label,
.mortgage-calculator .input-group label,
.stock-return-calculator .input-group label,
.body-fat-calculator .input-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.compound-interest-calculator .input-group input,
.retirement-calculator .input-group input,
.tax-calculator .input-group input,
.discount-calculator .input-group input,
.salary-calculator .input-group input,
.mortgage-calculator .input-group input,
.stock-return-calculator .input-group input,
.body-fat-calculator .input-group input,
.body-fat-calculator .input-group select {
  width: 100%;
  padding: 10px;
  font-size: 16px;
}

.compound-interest-calculator button,
.retirement-calculator button,
.tax-calculator button,
.discount-calculator button,
.salary-calculator button,
.mortgage-calculator button,
.stock-return-calculator button,
.body-fat-calculator button {
  margin: 10px;
}

.compound-interest-calculator .reset-button,
.retirement-calculator .reset-button,
.tax-calculator .reset-button,
.discount-calculator .reset-button,
.salary-calculator .reset-button,
.mortgage-calculator .reset-button,
.stock-return-calculator .reset-button,
.body-fat-calculator .reset-button {
  background-color: #6b7280;
}

.compound-interest-calculator .reset-button:hover,
.retirement-calculator .reset-button:hover,
.tax-calculator .reset-button:hover,
.discount-calculator .reset-button:hover,
.salary-calculator .reset-button:hover,
.mortgage-calculator .reset-button:hover,
.stock-return-calculator .reset-button:hover,
.body-fat-calculator .reset-button:hover {
  background-color: #4b5563;
}

.compound-interest-calculator .result,
.retirement-calculator .result,
.tax-calculator .result,
.discount-calculator .result,
.salary-calculator .result,
.mortgage-calculator .result,
.stock-return-calculator .result,
.body-fat-calculator .result {
  margin-top: 20px;
  font-size: 24px;
  font-weight: bold;
}

.salary-calculator .result h3,
.stock-return-calculator .result h3 {
  margin: 10px 0;
  font-size: 20px;
  font-weight: bold;
}

/* 학점 계산기 스타일 */
.content-box.gpa-calculator {
  text-align: center;
}

.gpa-calculator .course-input {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  max-width: 600px;
}

.gpa-calculator .course-input .input-group {
  margin-right: 10px;
  flex: 1;
}

.gpa-calculator .course-input .input-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.gpa-calculator .course-input .input-group input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
}

.gpa-calculator .remove-button {
  background-color: #e11d48;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.gpa-calculator .remove-button:hover {
  background-color: #be123c;
}

.gpa-calculator .add-button {
  background-color: #10b981;
  color: white;
  margin-bottom: 20px;
}

.gpa-calculator .add-button:hover {
  background-color: #059669;
}

.gpa-calculator button {
  margin: 10px;
}

.gpa-calculator .reset-button {
  background-color: #6b7280;
}

.gpa-calculator .reset-button:hover {
  background-color: #4b5563;
}

.gpa-calculator .result {
  margin-top: 20px;
  font-size: 24px;
  font-weight: bold;
}

/* 홈 컨테이너 */
.home-container {
  padding: 20px;
  background-color: #f9fafb;
}

/* 메뉴 그리드 */
.menu-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

/* 메뉴 아이템 */
.menu-item {
  text-decoration: none;
  color: inherit;
}

.menu-content {
  background-color: white;
  border-radius: 8px;
  padding: 30px 20px;
  text-align: center;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.menu-content:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.menu-content h2 {
  font-size: 1.5rem;
  margin-top: 15px;
  color: #1f2937;
}

.menu-content .menu-icon {
  font-size: 2.5rem;
  color: #2563eb;
}

.delete-button {
  background-color: #e11d48; /* 빨간색 */
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 16px;
  cursor: pointer;
  margin-left: 10px;
}

.delete-button:hover {
  background-color: #be123c; /* hover 시 어두운 빨간색 */
}

/* 로또 번호 추천 스타일 */
.lotto-numbers {
  display: flex;
  flex-wrap: wrap; /* 모바일에서도 줄바꿈이 가능하도록 설정 */
  justify-content: center;
  margin-top: 20px;
}

.lotto-number {
  display: inline-block;
  margin: 5px; /* 여백 추가 */
  background-color: #2563eb;
  color: white;
  font-size: 24px;
  font-weight: bold;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  text-align: center;
}

/* TicTacToe.css */
.tictactoe .board {
  display: grid;
  grid-template-columns: repeat(3, 100px); /* 칸의 크기를 조정 */
  grid-template-rows: repeat(3, 100px);
  gap: 5px;
  margin: 20px 0;
}

.tictactoe .square {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  border: 2px solid #333;
  font-size: 2.5rem; /* X와 O의 크기를 키움 */
  font-weight: bold;
  cursor: pointer;
}

.tictactoe .square.filled {
  pointer-events: none; /* 이미 채워진 칸은 클릭 불가 */
}

.tictactoe .game-info {
  margin-top: 20px;
}

.tictactoe button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #2563eb;
  color: white;
  border: none;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.tictactoe button:hover {
  background-color: #1d4ed8;
}

.color-grid {
  display: flex;
  justify-content: center; /* 수평 중앙 정렬 */
  align-items: center;     /* 수직 중앙 정렬 */
  flex-wrap: wrap;         /* 여러 줄로 배치 */
  margin-top: 20px;
}

.color-grid div {
  margin: 10px;
}


/* Loan Calculator Styles */

.content-box.loan-calculator {
  text-align: center;
}

.loan-calculator .loan-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loan-calculator .input-group {
  margin-bottom: 20px;
  width: 100%;
  max-width: 400px;
}

/* Schedule and Table Styles */
.schedule {
  margin-top: 20px;
}

.table-container {
  width: 100%;
  overflow-x: auto; /* 가로 스크롤 허용 */
}

table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto; /* 테이블 레이아웃 자동 조정 */
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: right;
  font-size: 12px; /* 모바일에서 가독성을 높이기 위해 글씨 크기 조절 */
  white-space: nowrap; /* 내용이 줄 바꿈되지 않도록 설정 */
  word-break: break-word; /* 단어가 넘어갈 경우 줄 바꿈 */
}

th {
  background-color: #f2f2f2;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

tr:hover {
  background-color: #ddd;
}

.description {
  margin-top: 20px;
  font-size: 14px;
  color: #555;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .content-box {
    padding: 20px;
    max-width: 90%;
  }

  .loan-calculator .input-group {
    max-width: 100%;
  }

  .schedule {
    margin-top: 15px;
  }

  table {
    font-size: 10px; /* 폰트 크기 더 줄이기 */
  }

  th, td {
    padding: 6px; /* 패딩 줄이기 */
  }

  .description {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .loan-calculator .loan-form {
    padding: 10px;
  }

  table {
    font-size: 8px; /* 매우 작은 화면용 폰트 크기 */
  }

  th, td {
    padding: 4px; /* 패딩 더 줄이기 */
  }
}

/* Optional: Improve table responsiveness */

table {
  table-layout: auto;
}

th, td {
  word-break: break-word;
}

/* Base Converter 스타일 */
.base-converter {
  text-align: center;
  padding: 20px;
}

.base-input {
  padding: 12px;
  margin-bottom: 15px;
  font-size: 16px;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  width: 100%;
  max-width: 300px;
}

.base-select {
  padding: 12px;
  margin-bottom: 15px;
  font-size: 16px;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  width: 100%;
  max-width: 300px;
}


.base-converter-result {
  font-size: 1.2rem;          /* 기본 폰트 크기 */
  padding: 10px;              /* 결과 텍스트 주위에 여백 */
  border-radius: 8px;         /* 부드러운 모서리 */
  max-width: 100%;            /* 최대 너비를 100%로 설정 */
  max-height: 200px;          /* 최대 높이 설정 */
  overflow-y: auto;           /* 결과가 넘칠 때 세로 스크롤 추가 */
  overflow-x: hidden;         /* 가로 스크롤 방지 */
  word-break: break-all;      /* 모든 단어에서 줄바꿈을 강제 */
  white-space: normal;        /* 텍스트를 자동으로 줄바꿈 */
}
